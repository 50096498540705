import {createRouter, createWebHistory} from 'vue-router';

import { routesConfig } from "./routesConfig";
import axios from "axios";

const routes = [...routesConfig]
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((routeTo, routeFrom, next) => {
    const user = JSON.parse(window.localStorage.getItem('CURRENT_USER'));

    document.title = "Shopexperts";
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
    const isAdmin = routeTo.matched.some((route) => route.meta.isAdmin)
    const isExpert = routeTo.matched.some((route) => route.meta.isExpert)
    const isClient = routeTo.matched.some((route) => route.meta.isClient)

    if (!authRequired) return next();


    if (isAdmin) {
        if (user && user.role_id === 1) {
            next();
        } else {
            window.localStorage.removeItem('CURRENT_USER')
            window.localStorage.removeItem('CURRENT_TOKEN')

            delete axios.defaults.headers.common['Authorization'];

            redirectToLogin('admin-login')
        }
    } else if (isClient) {
        if (user && user.role_id === 2) {
            next();
        } else {
            window.localStorage.removeItem('CURRENT_USER')
            window.localStorage.removeItem('CURRENT_TOKEN')

            delete axios.defaults.headers.common['Authorization'];

            redirectToLogin('client-login')
        }
    } else if (isExpert) {
        if (user && user.role_id === 3) {
            next();
        } else {
            window.localStorage.removeItem('CURRENT_USER')
            window.localStorage.removeItem('CURRENT_TOKEN')

            delete axios.defaults.headers.common['Authorization'];

            redirectToLogin('expert-login')
        }
    } else {
        next()
    }


    function redirectToLogin(type) {
        // Pass the original route to the login component
        next({
            name: type,
        })
    }
});
export default router